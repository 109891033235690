import Head from "next/head";
import Logo from '../../public/images/logo.svg'

export default function JsonLdHome() {
    const url = process.env.NEXTAUTH_URL;
    const logo = url + Logo.src
    const articleStructuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Home",
        "url": url,
        "logo": logo,
        "breadcrumb": {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "item": url,
                "name": "Home",
                
            }]
        }
    };
    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(articleStructuredData),
                }}
            />
        </Head>
    );
}
