import Link from "next/link";
import { useCategoryProductMenu } from "/lib/hooks/useCategoryProductMenu";

const handleHeaderClick = async (categoryName, eventCommonObj) => {
    const gaModule = await import('/lib/ga');
    gaModule.HeaderClick(categoryName, eventCommonObj);
}

const CategoryProductMenu = ({ websiteurl }) => {
    const {
        menu,
        stickClass,
        router,
        categoryUrlSuffix,
        eventCommonObj
    } = useCategoryProductMenu();

    if (menu?.length == 0) {
        return null
    }

    return <>
        <div className={`category-product-menu ${stickClass ? stickClass : ''}`}>
            <ul>
                {menu.map((topcategory) => (
                    topcategory.url_path == 'free-trial' ?
                        <li key={topcategory.id}>
                            <Link
                                prefetch={false}
                                href={topcategory.url_path}
                                as={`${websiteurl}/${topcategory.url_path}`}
                                className={
                                    router.asPath ==
                                        `/${topcategory.url_path}`
                                        ? "active"
                                        : ""
                                }>

                                {topcategory.name} {(topcategory?.category_tag) ? <span className="new">{topcategory.category_tag}</span> : ''}

                            </Link>
                        </li> :
                        <li key={topcategory.id}>
                            <Link
                                prefetch={false}
                                href={{
                                    pathname: "/_url-resolver",
                                    query: {
                                        pathname: `/${topcategory.url_path}${categoryUrlSuffix}`,
                                        type: "CATEGORY",
                                        url_path: topcategory.url_path,
                                        url_key: topcategory.url_key,
                                        cat_id: topcategory.id,
                                    },
                                }}
                                as={`${websiteurl}/${topcategory.url_path}${categoryUrlSuffix}`}
                                onClick={() => handleHeaderClick(topcategory.name, eventCommonObj)}
                                className={
                                    router.asPath ==
                                        `/${topcategory.url_path}${categoryUrlSuffix}`
                                        ? "active"
                                        : ""
                                }>

                                {topcategory.name}{(topcategory?.category_tag) ? <span className="new">{topcategory.category_tag}</span> : ''}

                            </Link>
                        </li>
                ))}
                <li onClick={() => handleHeaderClick('All_Products', eventCommonObj)}><Link prefetch={false} href={`${websiteurl}/shop.html`}>All Products</Link></li>
            </ul>
        </div>
        <div className="sticky_category_margin"></div>
    </>;
}

export default CategoryProductMenu;