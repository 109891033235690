import { useEffect, useState } from "react";
import { getDataFronSession } from "../services/helpers/getCountryData";
import { useRouter } from "next/router";

export const useNotifyModal = () => {
    const router = useRouter();
    const [showNotifyModal, setShowNotifyModal] = useState(false);

    const handleModalClose = () => {
        setShowNotifyModal(false);
        sessionStorage.setItem('notifyPopup', '1');
    }

    useEffect(() => {
        const notifyPopupOpened = getDataFronSession('notifyPopup'); // Popup should not come if closed by user
        const countryValueInSession = getDataFronSession('countryValue'); // Store value in session, so api call once

        // Get country value from server headers
        const getCountryValue = async() => {
            try {
                const data = await fetch('/api/getCountryValue',{
                    method: 'POST'
                });
                const country = await data.json();

                sessionStorage.setItem('countryValue', country);

                if(country != 'IN' && !notifyPopupOpened){
                    setShowNotifyModal(true);
                }
            } catch (error) {
                console.log('error',error);
            }
        }

        // if value not present in session, get it from api call otherwise use the value
        if(!countryValueInSession){
            getCountryValue();
        } else {
            if(countryValueInSession != 'IN' && !notifyPopupOpened){
                setShowNotifyModal(true);
            }
        }
       
    },[router.asPath])


    return {
        showNotifyModal,
        handleModalClose
    }
}