import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getReduxSession } from "../sessionHandler";
import { useCookies } from "react-cookie";
import { EventCommonObj } from "../services/helpers/eventCommonObject";

export const useCategoryProductMenu = () => {
    const menu = useSelector((state) => state.menu);
    const router = useRouter();
    const categoryUrlSuffix = ".html";
    const session = getReduxSession(true);
    const [storecookie, setStoreCookie] = useCookies(["store"]);
    const eventCommonObj = EventCommonObj(session, storecookie);
    const [stickClass, setStickClass] = useState('');

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 120 ? setStickClass('sticky_category') : setStickClass('')
        };
        window.addEventListener('scroll', scrollHandler);
        scrollHandler(); // Explicit call so that the navbar gets blurred if current page offset is `window.pageYOffset > 20`

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, []);

    return {
        menu,
        stickClass,
        router,
        categoryUrlSuffix,
        eventCommonObj
    }
}