const countryMap = {
    'GB': 'hreflang_url',
    'IN': ''
}

export const getCountryWiseAttribute = (value) => {
    const country =  value 
    return countryMap.hasOwnProperty(country) ? countryMap[country] : 'redirect_url'
}

export const getDataFronSession = (keyName) => {
    const dataFromStorage = sessionStorage.getItem(keyName) 
    
    if(dataFromStorage){
        return dataFromStorage;
    }

    return null;
}